import { addClass, removeClass } from './utils';

const $footer = document.querySelector('footer');
const $buyBtn = document.querySelector('.buy-pride');
const cartClass = 'cart-active';

document.addEventListener('blackbox.widgets.statechanged', (e) => {
  if (e.detail.active) {
    addClass($footer, cartClass);
    addClass($buyBtn, cartClass);
  } else {
    removeClass($footer, cartClass);
    removeClass($buyBtn, cartClass);
  }
});

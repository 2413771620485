import { toggleClass, addClass, removeClass } from './utils';

const $btn = document.querySelector('.intro .btn');
const $sample = document.querySelector('.intro .sample');
const $cards = $sample.querySelectorAll('img');

$btn.addEventListener('click', (e) => {
  e.preventDefault();
  e.stopPropagation();
 
  toggleClass($sample, 'show');
});

document.addEventListener('click', () => {
  removeClass($sample, 'show');
});

Array.prototype.forEach.call($cards, ($card) => {
  $card.addEventListener('click', (e) => {
    e.stopPropagation();
    
    // Remove select class on all
    Array.prototype.forEach.call($cards, ($c) => {
      removeClass($c, 'selected');
    });

    // Add to the one selected
    addClass($card, 'selected');
  }, false);
});

const $containers = document.querySelectorAll('.animated-gradient');
const pairs = [];

document.addEventListener("DOMContentLoaded", function() { 
  Array.prototype.forEach.call($containers, ($container) => {
    const width = $container.offsetWidth;
    const height = $container.offsetHeight;
    const diameter = Math.sqrt(width * width + height * height);

    let $el = document.createElement('div');
    $el.classList.add('animated-gradient-rotating');
    $el.style.width = $el.style.height = `${diameter}px`;

    $container.appendChild($el);
    pairs.push({
      container: $container,
      rotating: $el,
    });
  });
});

window.addEventListener('resize', (e) => {
  pairs.forEach((p) => {
    const width = p.container.offsetWidth;
    const height = p.container.offsetHeight;
    const diameter = Math.sqrt(width * width + height * height);

    p.rotating.style.width = p.rotating.style.height = `${diameter}px`;
  });
});

const $links = document.querySelectorAll('[data-scrollto]');

Array.prototype.forEach.call($links, ($link) => {
  $link.scrollIntoView && $link.addEventListener('click', (e) => {
    e.preventDefault();

    const destination = $link.getAttribute('data-scrollto');

    document.querySelector(destination).scrollIntoView({
      behavior: 'smooth'
    })
  });
});

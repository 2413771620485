(function () {

  /* Extend whoa.Element
  ------------------------------------- */

  let className = "Parade";
  let c = window[className] = function () { this.init() };
  let p = c.prototype = new whoa.Element();
  
  
  /* Properties
  ------------------------------------- */

  p.city;
  p.cityBackgroundA;
  p.cityBackgroundB;
  p.cityBackgroundC;
  p.cityBackgroundD;
  p.cityWidth = 2704;
  
  p.characterData;
  p.parade;
  p.characterSetA;
  p.characterSetB;
  p.charactersA;
  p.charactersB;
  p.characterSetWidth;
  p.increment;
  
  p.characterHeight = 400;
  p.paradeOffset = 112;
  p.lowerRowOffset = 110;
  p.characterSpacing = 20;
  p.regularIncrement = 2.50;
  p.hoverIncrement = 0.40;
  
  p.mode = "desktop";
  
  
  /* Constructor
  ------------------------------------- */
  
  p.init = function () {

    this.rootElementInit({id:"parade", height:640, position:"relative", overflow:"hidden"});
    // this.overflowX = "scroll";
    this.webkitOverflowScrolling = "touch";
    var _this = this;
    
    // fonts
    
    whoa.addFontFace("Silverspoon", 700, "../fonts/silverspoon.ttf");
    // whoa.addFontFace("Helvetica Neue", 400, "../fonts/h400.ttf");
    
    // globals
    
    window.globals = {
      hbHeaderStyle: {fontFamily:"Silverspoon", fontWeight:700, fontSize:18},
      hbBodyStyle: {fontFamily:"Helvetica Neue", fontWeight:400, fontSize:14, lineHeight:18}
    };
    
    // characterData
    
    this.characterData = [
      {id:"babadook", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:-10, shadowScale:0.8, shadowYOffset:0, shadowXOffset:-14, dir:"Babadook", charName:"The Babadook", imageCount:3, hoverText:"Accidental gay icon. Memes be crazy."},
      {id:"beyonce", yOffset:0, hoverBoxYOffset:-10, hoverBoxXOffset:-8, shadowScale:1, shadowYOffset:3, shadowXOffset:-20, dir:"Beyonce", charName:"Beyonce", imageCount:4, hoverText:"Beyonce is enough."},
      {id:"brunch", yOffset:15, hoverBoxYOffset:30, hoverBoxXOffset:0, shadowScale:1.4, shadowYOffset:-11, shadowXOffset:-20, dir:"Brunch", charName:"Brokeback Boys & Skating Queens", imageCount:4, hoverText:"The most dazzling ice skaters paired with the saddest cowboys. If this brunch could talk."},
      {id:"cars", yOffset:40, hoverBoxYOffset:60, hoverBoxXOffset:0, shadowScale:1.3, shadowYOffset:-45, shadowXOffset:-20, dir:"Cars", charName:"Prius & Subaru", imageCount:3, hoverText:"Hybrid is a sexuality."},
      {id:"cher", yOffset:0, hoverBoxYOffset:-3, hoverBoxXOffset:-8, shadowScale:0.8, shadowYOffset:3, shadowXOffset:-10, dir:"Cher", charName:"Cher", imageCount:4, hoverText:"Noted Chad Michaels impersonator."},
      //{id:"divine", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:0, shadowScale:0.9, shadowYOffset:0, shadowXOffset:0, dir:"Divine", charName:"Divine", imageCount:5, hoverText:"05 Here is the text that goes inIt is just the best. It is just the best. It is just the best. It is just the best. It is just the best.  that hover box. It is just the best. Okay."},
      {id:"ellen", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:-8, shadowScale:0.8, shadowYOffset:0, shadowXOffset:-4, dir:"Ellen", charName:"Ellen", imageCount:4, hoverText:"Yep, she's gay."},
      {id:"equalRights", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:9, shadowScale:0.8, shadowYOffset:-4, shadowXOffset:6, dir:"EqualRights", charName:"Equal Rights", imageCount:8, hoverText:"Do you have a moment for equal rights?"},
      {id:"grindr", yOffset:10, hoverBoxYOffset:6, hoverBoxXOffset:0, shadowScale:0.8, shadowYOffset:0, shadowXOffset:0, dir:"Grindr", charName:"Grindr", imageCount:5, hoverText:"The first geo-social app geared toward gay and bisexual men, designed to help them meet and fuck ASAP."},
      //{id:"johnWaters", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:-6, shadowScale:0.8, shadowYOffset:7, shadowXOffset:0, dir:"John", charName:"John Waters", imageCount:4, hoverText:"09 Here is the text that goes in that hover box."},
      {id:"johnAndDivine", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:0, shadowScale:1.3, shadowYOffset:0, shadowXOffset:0, dir:"JohnAndDivine", charName:"John Waters & Divine", imageCount:5, hoverText:"A divine director and director of Divine and also Divine."},
      {id:"judgeJudy", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:0, shadowScale:0.9, shadowYOffset:0, shadowXOffset:0, dir:"JudgeJudy", charName:"Judge Judy", imageCount:3, hoverText:"Not an actual judge. Not an actual lesbian. But she is Ru Paul's favorite TV show."},
      {id:"ladyGaga", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:0, shadowScale:0.8, shadowYOffset:0, shadowXOffset:-10, dir:"LadyGaga", charName:"Lady Gaga", imageCount:4, hoverText:"The Khaleesi of Gays."},
      {id:"leather", yOffset:0, hoverBoxYOffset:25, hoverBoxXOffset:0, shadowScale:1.2, shadowYOffset:-14, shadowXOffset:0, dir:"Leather", charName:"Leather Daddies", imageCount:4, hoverText:"Dating back to the 1950s, inspired by post-WWII biker culture, leather daddies have cemented their place in gay culture and in the mouths of excited bois."},
      {id:"marsha", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:6, shadowScale:0.8, shadowYOffset:0, shadowXOffset:-9, dir:"Marsha", charName:"Marsha P. Johnson", imageCount:4, hoverText:"One of the the many people who fought for our rights in the Stonewall uprising."},
      {id:"OITNB", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:10, shadowScale:1.5, shadowYOffset:5, shadowXOffset:0, dir:"OITNB", charName:"Orange Is The New Black", imageCount:7, hoverText:"Icons come in all shapes and colors. These ones come in orange."},
      {id:"pridePack", yOffset:0, hoverBoxYOffset:30, hoverBoxXOffset:0, shadowScale:0.8, shadowYOffset:0, shadowXOffset:-4, dir:"PridePack", charName:"The Pride Pack", imageCount:4, hoverText:"The most important corporate co-opting of pride since Absolut Vodka."},
      {id:"prince", yOffset:0, hoverBoxYOffset:10, hoverBoxXOffset:-15, shadowScale:1.15, shadowYOffset:-14, shadowXOffset:-20, dir:"Prince", charName:"Prince", imageCount:12, hoverText:"A truly transcendent human being bestowed upon us by whatever God might be."},
      {id:"ruPaul", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:0, shadowScale:0.7, shadowYOffset:-4, shadowXOffset:0, dir:"RuPaul", charName:"RuPaul", imageCount:7, hoverText:"We're all born naked and the rest is drag."},
      {id:"simmons", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:-28, shadowScale:0.8, shadowYOffset:0, shadowXOffset:-25, dir:"Simmons", charName:"Richard Simmons", imageCount:4, hoverText:"Love yourself. Move your body. Watch your portions."},
      {id:"xena", yOffset:0, hoverBoxYOffset:12, hoverBoxXOffset:16, shadowScale:0.9, shadowYOffset:0, shadowXOffset:0, dir:"Xena", charName:"Xena: Warrior Princess", imageCount:8, hoverText:"Original leather daddy."},
      {id:"janelle", yOffset:0, hoverBoxYOffset:0, hoverBoxXOffset:-25, shadowScale:1, shadowYOffset:0, shadowXOffset:-15, dir:"Janelle", charName:"Janelle Monae", imageCount:4, hoverText:"We love the way she makes us feel."},
      {id:"books", yOffset:40, hoverBoxYOffset:40, hoverBoxXOffset:0, shadowScale:0.8, shadowYOffset:-44, shadowXOffset:-19, dir:"Books", charName:"Books", imageCount:5, hoverText:"We love the way she makes us feel."},
      {id:"waldo", yOffset:0, hoverBoxYOffset:6, hoverBoxXOffset:-24, shadowScale:0.8, shadowYOffset:0, shadowXOffset:-35, dir:"Waldo", charName:"Waldo", imageCount:3, hoverText:"We love the way she makes us feel."},
      {id:"water", yOffset:48, hoverBoxYOffset:50, hoverBoxXOffset:-3, shadowScale:0.7, shadowYOffset:-52, shadowXOffset:-20, dir:"Water", charName:"Water", imageCount:4, hoverText:"We love the way she makes us feel."}
    ];
    this.characterData = whoa.shuffleArray(this.characterData); // randomize order

    // Merge the character data
    this.characterData.forEach(function (char) {
      var info = window.characterInfo[char.id];
      if (info) {
        char.charName = info.charName;
        char.hoverText = info.hoverText;
      }
    });

    // city
    
    this.cityContainer = new whoa.Element({id:"cityContainer", height:640, overflow:"hidden"});
    this.addChild(this.cityContainer);
    
    this.city = new whoa.Element({id:"city", height:640, position:"relative"});
    this.cityContainer.addChild(this.city);
    
    this.cityBackgroundA = new whoa.Element({id:"cityBackgroundA", tag:"img", src:"../images/parade/city.png"});
    this.city.addChild(this.cityBackgroundA);
    
    this.cityBackgroundB = new whoa.Element({id:"cityBackgroundB", tag:"img", src:"../images/parade/city.png", left:this.cityWidth});
    this.city.addChild(this.cityBackgroundB);
    
    this.cityBackgroundC = new whoa.Element({id:"cityBackgroundC", tag:"img", src:"../images/parade/city.png", left:this.cityWidth * 2});
    this.city.addChild(this.cityBackgroundC);
    
    this.cityBackgroundD = new whoa.Element({id:"cityBackgroundD", tag:"img", src:"../images/parade/city.png", left:this.cityWidth * 3});
    this.city.addChild(this.cityBackgroundD);
    
    this.animateCity();
    
    // characters
    
    this.parade = new whoa.Element({id:"parade", width:15000}); // container
    this.increment = this.regularIncrement;
    this.addChild(this.parade);
    
    this.characterSetA = new whoa.Element({id:"characterSetA", top:this.paradeOffset}); // main character set
    this.parade.addChild(this.characterSetA);
    this.charactersA = [];
    for (var i = 0; i < this.characterData.length; i++) {
      this.charactersA[i] = new Character(this.characterData[i], this.characterHeight);
      this.characterSetA.addChild(this.charactersA[i]);
      if (i % 2 == 1) {
        this.charactersA[i].y = this.lowerRowOffset;
        this.charactersA[i].zIndex = 1;
      }
      this.charactersA[i].marginLeft = this.characterSpacing;
    }
    
    this.characterSetB = new whoa.Element({id:"characterSetB", top:this.paradeOffset}); // a few more for visual overflow
    this.parade.addChild(this.characterSetB);
    this.charactersB = [];
    for (var i = 0; i < this.characterData.length; i++) {
      this.charactersB[i] = new Character(this.characterData[i], this.characterHeight);
      this.characterSetB.addChild(this.charactersB[i]);
      if (i % 2 == 1) {
        this.charactersB[i].y = this.lowerRowOffset;
        this.charactersB[i].zIndex = 1;
      }
      this.charactersB[i].marginLeft = this.characterSpacing;
    }
    
    //setTimeout(function () { console.log(_this.characterSetA.liveWidth) }, 50);
    this.characterSetWidth = 6522.65625;//5838.672;//5960;//this.characterSetA.liveWidth; // we are hard-coding this for now!
    this.characterSetB.x = this.characterSetWidth;
    this.animateParade();
    this.cityContainer.width = this.characterSetWidth + 350; // for mobile side scrolling
    this.cityContainer.left = -50;
  }
  
  
  /* Methods
  ------------------------------------- */
  
  p.onScroll = function (e) {
    
    window.parade.updateMobileLabels();
  }
  
  p.updateMobileLabels = function () {
    
    for (var i = 0; i < this.charactersA.length; i++) {
      
      var characterPosition = this.charactersA[i].liveLeft + this.charactersA[i].liveWidth/2;
      
      if (characterPosition > 120 && characterPosition < (W - 120)) {
        if (!this.charactersA[i].hoverBoxOpen) this.charactersA[i].onOver();
      }
      else {
        if (this.charactersA[i].hoverBoxOpen) this.charactersA[i].onOut();
      }
    }
  }
  
  p.checkMobileMode = function () {
    
    if (this.mode == "desktop") {
        
      // stop parade and reset position
      this.stopParade();
      this.parade.x = 0;
      
      // hide overflow characters
      this.characterSetB.display = "none";
      this.characterSetA.left = 100;
      this.parade.width = this.characterSetWidth + 100;
      
      // add scroll listener
      window.addEventListener("scroll", this.onScroll, true);
      this.updateMobileLabels(); // run it once
      this.overflowX = "scroll";
      
      // switch mode
      this.mode = "mobile";
    }
  }

  p.checkDesktopMode = function () {
    
    if (this.mode == "mobile") {
        
      // restart parade
      this.animateParade();
      
      // show overflow characters
      this.characterSetB.display = "";
      this.characterSetA.left = 0;
      this.parade.width = 15000;
      
      // change scrolling behavior
      this.overflow = "hidden";
      this.displayElement.scrollTo(0, 0);
      
      // remove scroll listener
      window.removeEventListener("scroll", this.onScroll);
      
      // switch mode
      this.mode = "desktop";
    }
  }
  
  p.animateParade = function () {

    this.parade.x = -this.characterSetWidth;
    this.startParade();
  }
  
  p.startParade = function () {
    
    TweenLite.ticker.addEventListener("tick", this.moveParade);
  }
  
  p.stopParade = function () {
    
    TweenLite.ticker.removeEventListener("tick", this.moveParade);
  }
  
  p.moveParade = function () { 
    
    window.parade.parade.x += window.parade.increment;
    if (window.parade.parade.x >= 0) window.parade.parade.x = -window.parade.characterSetWidth;
  }
  
  p.hoverParade = function () {
    
    this.animateTo({increment:this.hoverIncrement, time:0.40, ease:Quad.easeOut});
  }
  
  p.unhoverParade = function () {
    
    this.animateTo({increment:this.regularIncrement, time:0.40, ease:Quad.easeIn});
  }
  
  p.animateCity = function () {
    
    this.city.killAnimations();
    this.city.x = 0;
    
    var _this = this;
    this.city.animateTo({x:-this.cityWidth, time:60, ease:Linear.easeNone, onComplete: function () { _this.animateCity() }});
  }
  
  p.resize = function () {

    if (W < mobileWidth) {
      this.checkMobileMode();
      
      for (var i = 0; i < this.charactersA.length; i++) this.charactersA[i].checkMobileMode();
      for (i = 0; i < this.charactersB.length; i++) this.charactersB[i].checkMobileMode();
    }
    else {
      this.checkDesktopMode();
      
      for (i = 0; i < this.charactersA.length; i++) this.charactersA[i].checkDesktopMode();
      for (i = 0; i < this.charactersB.length; i++) this.charactersB[i].checkDesktopMode();
    }
  }
}());
(function () {

  /* Extend whoa.Element
  ------------------------------------- */

  let className = "Character";
  let c = window[className] = function (args, h) { this.init(args, h) };
  let p = c.prototype = new whoa.Element();
  
  
  /* Properties
  ------------------------------------- */

  p.characterInfo;
  p.frameContainer;
  p.frames;
  p.hoverBox;
  p.hoverBoxContainer;
  p.arrow;
  p.shadowCircle;
  
  
  /* Constructor
  ------------------------------------- */
  
  p.init = function (args, h) {

    this.characterInfo = args;
    var _this = this;
    this.mode = "desktop";
    this.hoverBoxOpen = false;
    
    this.rootElementInit({id:this.characterInfo.id, position:"relative", display:"inline-block", cursor:"pointer"});
    
    // build hoverBox
    
    this.hoverBoxContainer = new whoa.Element({id:this.id + "-hoverBoxContainer", left:"50%"});
    this.addChild(this.hoverBoxContainer);
    this.hoverBoxContainer.y = this.hoverBoxContainer.Y = this.characterInfo.hoverBoxYOffset + this.characterInfo.yOffset;
    this.hoverBoxContainer.x = this.characterInfo.hoverBoxXOffset;
    
    this.hoverBox = new whoa.Element({id:this.id + "-hoverBox", left:-160, width:320, borderRadius:8, bg:"#FFFFFF", bottom:0, zIndex:1, transformOrigin:"bottomCenter"});
    this.hoverBoxContainer.addChild(this.hoverBox);
    this.hoverBox.scale = 0; // hide that shiz
    
    this.hoverBox.header = new whoa.Element({id:this.id + "-hoverBoxHeader", height:27, paddingLeft:"4%", paddingTop:5, position:"relative", bg:"linear-gradient(100deg, rgb(250, 165, 222), rgb(253, 237, 193), rgb(173, 255, 217), rgb(82, 233, 249), rgb(146, 233, 249))", props:globals.hbHeaderStyle, overflow:"hidden"});
    this.hoverBox.addChild(this.hoverBox.header);
    this.hoverBox.header.html = this.characterInfo.charName;
    this.hoverBox.header.transformOrigin = "topCenter";
    
    this.hoverBox.borderElement = new whoa.Element({id:this.id + "-borderElement", width:"100%", height:"100%", borderWidth:2, left:-2, top:-2});
    this.hoverBox.addChild(this.hoverBox.borderElement);
    this.hoverBox.borderElement.borderRadius = this.hoverBox.borderRadius;
    
    this.arrow = new whoa.Element({id:this.id + "-arrow", width:12, height:12, bg:"#FFFFFF", bottom:0, rotation:45, bottom:-8, borderWidth:2});
    this.hoverBox.addChild(this.arrow);
    this.arrow.left = this.hoverBox.width/2 - this.arrow.width/2;
    
    this.arrowCover = new whoa.Element({id:this.id + "-arrowCover", width:50, height:11, bg:"#FFFFFF", bottom:0});
    this.hoverBox.addChild(this.arrowCover);
    this.arrowCover.left = this.hoverBox.width/2 - this.arrowCover.width/2;
    
    this.hoverBox.line = new whoa.Element({id:this.id + "-hoverBoxLine", width:"100%", height:2, bg:"#000000", left:0, bottom:0});
    this.hoverBox.header.addChild(this.hoverBox.line);
    
    this.hoverBox.copyContainer = new whoa.Element({id:this.id + "-hoverBoxCopyContainer", width:"92%", left:"4%", paddingTop:10, paddingBottom:10, position:"relative", overflow:"hidden"});
    this.hoverBox.addChild(this.hoverBox.copyContainer);
    
    this.hoverBox.copy = new whoa.Element({id:this.id + "-hoverBoxCopy", props:globals.hbBodyStyle, position:"relative"});
    this.hoverBox.copyContainer.addChild(this.hoverBox.copy);
    this.hoverBox.copy.html = this.characterInfo.hoverText;
    
    // build frames
    
    this.frameContainer = new whoa.Element({id:this.id + "-frameContainer", position:"relative", height:h, top:this.characterInfo.yOffset});
    this.addChild(this.frameContainer);
    
    this.shadowCircle = new whoa.Element({id:this.id + "-shadowCircle", borderRadius:100, height:40, width:40, bg:"#333333", left:"50%", x:-20, bottom:0});
    this.frameContainer.addChild(this.shadowCircle);
    this.shadowCircle.scaleX = this.characterInfo.shadowScale * 8.00;
    this.shadowCircle.y = -7 + this.characterInfo.shadowYOffset;
    this.shadowCircle.x = this.characterInfo.shadowXOffset;
    this.shadowCircle.opacity = 0;
    
    this.frames = [];
    for (var i = 0; i < this.characterInfo.imageCount; i++) {
      
      var frame = new whoa.Element({id:this.id + "-frame" + i, tag:"img", src:"../images/parade/characters/" + this.characterInfo.dir + "/" + i + ".PNG", height:"100%"});
      if (i == (this.characterInfo.imageCount - 1)) frame.position = "relative"
      this.frameContainer.addChild(frame);
      this.frames[i] = frame;
      this.frames[i].opacity = 0;
    }
    this.animate();
    
    // 3D
    
    //this.create3D();
    
    // mouse interactions
    
    this.addEventListener("mouseenter", function () { _this.onOver() });
    this.addEventListener("mouseleave", function () { _this.onOut() });
  }
  
  
  /* Methods
  ------------------------------------- */
  
  p.create3D = function () {
    
    this.preserve3D = true;
    this.perspective = 1500;
    this.frameContainer.z = 1;
    
    this.circle3D = new whoa.Element({id:this.id + "-circle3D", width:320, height:180, borderRadius:500, bottom:-68, rotationX:80, preserve3D:true});
    //this.circle3D.bg = "#FF0000";
    this.circle3D.width = this.characterInfo.shadowScale * this.circle3D.width;
    this.circle3D.x = this.characterInfo.shadowScale * this.characterInfo.shadowXOffset;
    this.circle3D.y = this.characterInfo.shadowScale * (this.characterInfo.yOffset + this.characterInfo.shadowYOffset);
    this.addChild(this.circle3D);
    this.circle3D.display = "none";
    
    this.circle3D.balls = [];
    this.circle3D.ballContainers = [];
    var colors = ["rgb(250, 165, 222)", "rgb(253, 237, 193)", "rgb(173, 255, 217)", "rgb(82, 233, 249)", "rgb(146, 233, 249)"];
    var size = 8;
    var num = 40;
    for (var i = 0; i < num; i++) {
      var ball = new whoa.Element({width:size, height:size, left:-size/2, top:-size/2, borderRadius:size, preserve3D:true});
      ball.bg = "#000000";//whoa.blendColors(whoa.randItem(colors), "#000000", 0.20);
      ball.rotationY = 90;
      
      var ballContainer = new whoa.Element({width:this.circle3D.width/2, preserve3D:true, transformOrigin:"centerLeft"});
      ballContainer.addChild(ball);
      ball.x = ballContainer.width;
      ballContainer.rotationZ = 600/num * i;
      ballContainer.saveProps(["rotationZ"]);
      ballContainer.x = this.circle3D.width/2;
      ballContainer.y = this.circle3D.height/2;
      ballContainer.z = 0;
      
      this.circle3D.addChild(ballContainer);
      this.circle3D.balls[i] = ball;
      this.circle3D.ballContainers[i] = ballContainer;
    }
    
    var _this = this;
    this.addEventListener("mouseenter", function () { _this.onOver3D() });
    this.addEventListener("mouseleave", function () { _this.onOut3D() });
  }
  
  p.onOver3D = function () {
    
    this.circle3D.display = "";
    
    var t = 0.70;
    var sp = 0.0125;
    
    for (var i = 0; i < this.circle3D.balls.length; i++) {
      
      var ball = this.circle3D.balls[i];
      var ballContainer = this.circle3D.ballContainers[i];
      ball.killAnimations();
      ballContainer.killAnimations();
      
      ball.z = 50;
      ball.x = ballContainer.width;
      ball.opacity = 0;
      ball.scale = 0.2;
      ball.animateTo({opacity:1, delay:sp * i, time:0});
      ball.animateTo({scale:1, delay:sp * i, time:0.10});
      ball.animateTo({z:800, x:ballContainer.width/2, time:t, delay:sp * i + 0.01, ease:Linear.easeNone});
      
      var rZ = ballContainer.loadProp("rotationZ");
      ballContainer.rotationZ = rZ;
      ballContainer.animateTo({rotationZ:rZ + 90, time:t, delay:sp * i + 0.01, ease:Linear.easeNone});
    }
  }
  
  p.onOut3D = function () {
    
    this.circle3D.display = "none";
  }
  
  p.checkMobileMode = function () {
    if (this.mode == "desktop") {
      
      // disable hovers
      this.mouseEnabled = false;
      
      // set mode
      this.mode = "mobile";
    }
  }
  
  p.checkDesktopMode = function () {
    if (this.mode == "mobile") {

      // enable hovers
      this.mouseEnabled = true;

      // set mode
      this.mode = "desktop";
    }
  }
  
  p.onClick = function (e) {
    
    var _this = e.target.whoaElement.parent.parent;
    
    _this.onOver(e, true);
    
  }
  
  p.doHover = function () {
    
    var _this = this;
    var t = 0.55;
    var str = 3;
    this.hoverBoxContainer.y = this.hoverBoxContainer.Y + str;
    this.hoverBoxContainer.animateTo({y:this.hoverBoxContainer.Y - str, time:t, ease:Quad.easeInOut});
    this.hoverBoxContainer.animateTo({y:this.hoverBoxContainer.Y + str, delay:t, time:t, ease:Quad.easeInOut, onComplete: function () { _this.doHover() }});
    
  }
  
  p.onOver = function () {

    if (!this.hoverBoxOpen) {
      this.shadowCircle.opacity = 0.13;
  
      // animate
  
      var t1 = 0.16;
      var t2 = 0.16;
      var sp = 0.05;
  
      this.hoverBox.killAnimations();
      this.hoverBox.scale = 0.15;
      this.hoverBox.wiggleTo({time1:t1, time2:t2, scale:{to:1.00, overshoot:0.18}, wiggles:3, decay:0.38});
  
      this.hoverBox.y = 20;
      this.hoverBox.animateTo({y:0, time:0.25, ease:Quart.easeOut});
  
      this.hoverBox.header.killAnimations();
      this.hoverBox.header.scaleY = 1.80;
      this.hoverBox.header.wiggleTo({delay:sp, time1:t1, time2:t2, scaleY:{to:1.00, overshoot:0.17}, wiggles:3, decay:0.38});
  
      this.hoverBox.copy.killAnimations();
      this.hoverBox.copy.y = (this.hoverBox.header.scaleY - 1) * this.hoverBox.header.height;
      this.hoverBox.copy.wiggleTo({delay:sp, time1:t1, time2:t2, y:{to:0.01, overshoot:6}, wiggles:3, decay:0.36});
      
      this.doHover();
      
      if (window.parade.mode == "desktop") window.parade.hoverParade();
    }
    
    this.hoverBoxOpen = true;
  }
  
  p.onOut = function () {
    
    if (this.hoverBoxOpen) {
      this.shadowCircle.opacity = 0;
    
      // animate
    
      this.hoverBox.killAnimations();
      this.hoverBox.scale = 1;
      this.hoverBox.y = 0;
      this.hoverBox.animateTo({time:0.08, scale:0.00, y:20, ease:Quart.easeIn});
      
      if (window.parade.mode == "desktop") window.parade.unhoverParade();
    }
    
    this.hoverBoxOpen = false;
  }
  
  p.stopAnimation = function () {
    
    this.frameContainer.killAnimations(); // stop loop
    this.hoverBoxContainer.killAnimations(); // stop hover
    
    for (var i = 0; i < this.characterInfo.imageCount; i++) {
      this.frames[i].killAnimations(); // stop individual frame timers
      this.frames[i].opacity = 0;
    }
    
    this.frames[0].opacity = 1; // make first frame visible
  }
  
  p.animate = function () {
    
    var d = 0.13;
    var l = this.characterInfo.imageCount;
    
    this.frames[l-1].opacity = 0;
    this.frames[0].opacity = 1;
    
    for (var i = 1; i < l; i++) {
      this.frames[i].animateTo({opacity:1, time:0.001, delay:d * i});
      this.frames[i-1].animateTo({opacity:0, time:0.001, delay:d * i});
    }
    
    var _this = this;
    this.frameContainer.animateTo({time:d, delay:d * (l - 1), onComplete:function () { _this.animate() }});
  }
  
}());